import { Participant } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import React, { useEffect, useState } from 'react';
import { CloseSharp, VideocamOff } from '@material-ui/icons';
import MicOff from '@material-ui/icons/MicOff';

const useStyles = makeStyles({
  outerContainer: {
    padding: '0.3em',
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
  },
  innerContainer: {
    cursor: 'pointer',
    opacity: '0.8',
    '&:hover': {
      opacity: '1',
    },
  },
  nowDisabled: {
    color: 'red',
  },
});

export default function ParticipantControls({ participant }: { participant: Participant }) {
  const classes = useStyles();

  const { controlParticipantAction } = useAppState();
  const { getRole, room, participantsState, setParticipantsState } = useVideoContext();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => void 0, [participantsState]);

  const audioState = !(
    participantsState?.[participant.sid]?.audio_disabled ??
    participantsState?.[participant.identity]?.audio_disabled ??
    false
  );
  const videoState = !(
    participantsState?.[participant.sid]?.video_disabled ??
    participantsState?.[participant.identity]?.video_disabled ??
    false
  );

  const setAudioState = (value: boolean) => {
    if (participantsState) {
      participantsState[participant.sid] = { audio_disabled: !value, video_disabled: !videoState };
      setParticipantsState(participantsState);
    }
  };

  const setVideoState = (value: boolean) => {
    if (participantsState) {
      participantsState[participant.sid] = { audio_disabled: !audioState, video_disabled: !value };
      setParticipantsState(participantsState);
    }
  };

  if (
    room?.localParticipant.identity === participant.identity || // not me
    getRole(participant.identity) || // not spectator nor moderator
    getRole() !== 'moderator' || // I am moderator
    !participantsState // state of participants is loaded
  ) {
    return null;
  }

  const onClickAudio = () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    return (
      room &&
      controlParticipantAction(room.sid, participant.sid, audioState ? 'audio-disable' : 'audio-enable').then(() => {
        setAudioState(!audioState);
        setIsFetching(false);
      })
    );
  };

  const onClickVideo = () => {
    if (isFetching) {
      return;
    }
    setIsFetching(true);
    return (
      room &&
      controlParticipantAction(room?.sid, participant.sid, videoState ? 'video-disable' : 'video-enable').then(() => {
        setVideoState(!videoState);
        setIsFetching(false);
      })
    );
  };

  const onClickKick = () => {
    //return confirm(`Really kick off user ${participant.identity}?`) && controlParticipantAction(participant.sid, 'kick');
    return room && controlParticipantAction(room.sid, participant.sid, 'kick');
  };

  return (
    <div className={classes.outerContainer}>
      {audioState ? (
        <div className={classes.innerContainer} onClick={onClickAudio} title="Turn off audio from participant in room.">
          <MicOff />
        </div>
      ) : (
        <div className={classes.innerContainer} onClick={onClickAudio} title="Turn on audio">
          <MicOff className={classes.nowDisabled} />
        </div>
      )}
      {videoState ? (
        <div className={classes.innerContainer} onClick={onClickVideo} title="Turn off video from participant in room.">
          <VideocamOff />
        </div>
      ) : (
        <div className={classes.innerContainer} onClick={onClickVideo} title="Turn on video">
          <VideocamOff className={classes.nowDisabled} />
        </div>
      )}
      <div className={classes.innerContainer} onClick={onClickKick} title="Kick off participiant">
        <CloseSharp />
      </div>
    </div>
  );
}
